import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import MyPhoto from "./Images/EnesAtacan.jpg";
import ContactPhoto from "./Images/contact.png";
import html from "./Images/html.png";
import css from "./Images/css3.png";
import js from "./Images/js.png";
import ts from "./Images/ts.png";
import bootstrap from "./Images/bootstrap.png";
import tailwind from "./Images/tailwind.png";
import mui from "./Images/mui.png";
import reactIcon from "./Images/react.png";

import "./Style/Buttons.css";
import "./Style/Delay.css";
import Contact from "./ContactForm";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import Helmet from "react-helmet";

function Home() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;

  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | Home";
  const pageDescription =
    "Welcome to Juniour Frontend Developer Enes ATACAN's personal website. Here you can review Enes' resume and portfolio and send a message to Enes.";
  const keywords =
    "Enes Atacan, Home, Personal Website, Kişisel Web Site,Frontend Developer";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar
  return (
    <Box className={`delay ${showText ? "show" : ""}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Grid container height={"100vh"}>
        <Grid item xs={12} lg={6}>
          <img
            src={MyPhoto}
            style={{
              position: "relative",
              width: "100%",
              zIndex: -1,
              objectFit: "cover",
              opacity: 0.9,
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
            }}
            alt="Enes Atacan"
          />
        </Grid>
        <Grid pt={3} style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "70%",
              position: "relative",
              left: "50%",
              top: "40%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography variant="h2">
                Enes <br /> ATACAN
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
                <Typography variant="body2">Jr. Frontend Developer</Typography>
              </div>
            </div>
            <Typography variant="body2">Hi, welcome to my website.</Typography>
            <Typography variant="body2">
              On my website, I give you information about myself and the
              projects I have done.
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Button>
                <img src={html} style={{ width: "30px" }} />
              </Button>
              <Button>
                <img src={css} style={{ width: "30px" }} />
              </Button>
              <Button>
                <img src={js} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={ts} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={bootstrap} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={tailwind} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={mui} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={reactIcon} style={{ width: "50px" }} />
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid py={8} container bgcolor={"#daddd4"}>
        <Grid style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography color={"#5f8680"} variant="h3">
                About Me
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
              </div>
            </div>
            <Typography variant="body2">My brief summary</Typography>
          </div>
        </Grid>
        <Grid style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography variant="body2" mb={5}>
              Hello! I'm Enes, I'm 23 years old and I've been designing modern
              and user-friendly websites as a Frontend Developer for two years.
              People around me usually know me as a very curious and inquisitive
              person.
            </Typography>
            <Link to={"/about"}>
              <button className="containedButton">Read More</button>
            </Link>
          </div>
        </Grid>
      </Grid>

      <Grid py={8} container bgcolor={"#f3f4f1"}>
        <Grid py={4} style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div>
              <Typography color={"#5f8680"} variant="h3">
                Portfolio
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
              </div>
            </div>
            <Typography variant="body2">My experience to date</Typography>
          </div>
        </Grid>
        <Grid style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography variant="body2" mb={5}>
              During the 2 years I have been working in front-end software, I
              have developed some work experience and projects. If you would
              like to learn more, please click the button below
            </Typography>
            <Link to={"/portfolio"}>
              <button className="outlinedButton">Read More</button>
            </Link>
          </div>
        </Grid>
      </Grid>
      <Grid height={"100%"} container bgcolor={"#e7e9e2"}>
        <Grid item xs={12} lg={6}>
          <img
            src={ContactPhoto}
            style={{
              position: "relative",
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              opacity: 0.85,
            }}
            alt="Enes Atacan Contact Me"
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography style={{ color: "#557772" }} variant="h4">
            Contact Me
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />
          <Typography variant="body2" sx={{ letterSpacing: "0.1em" }}>
            I look forward to getting in touch with you.
          </Typography>
          <Typography
            width={"80%"}
            textAlign={"center"}
            variant="body2"
            mt={1}
            sx={{ letterSpacing: "0.1em" }}
          >
            You can send me a message via the form below or you can contact me
            by following me on my social media accounts.
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />

          <Stack spacing={5} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <Contact />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
