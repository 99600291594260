import React from "react";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Grid, Button, Stack, Typography } from "@mui/material";
import Helmet from "react-helmet";
import { useEffect, useState } from "react";
import "./Style/Delay.css";
import drinks from "./Images/coffee.png";
import rps from "./Images/rps.png";
import findSize from "./Images/findSize.png";
import pomodoro from "./Images/pomodoro.png";
import login from "./Images/login.png";
import meals from "./Images/meals.png";
import kanban from "./Images/kanban.png";

function Works() {
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | Works";
  const pageDescription =
    "Check out Enes ATACAN's projects with ReactJS: To do app, weather app";
  const keywords =
    "Enes Atacan, Jobs, Projects, Projeler, React, To do app, weather app";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Box className={`delay ${showText ? "show" : ""}`}>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="robots" content="noindex, follow" />
          <meta name="keywords" content={keywords} />
        </Helmet>
        <Stack height={"100px"}></Stack>
        <Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Typography variant="h2">Works</Typography>
          <Typography
            fontSize={"16px"}
            width={"60%"}
            mt={3}
            textAlign={"center"}
            variant="body2"
          >
            I've been working on various projects to improve myself and as every
            developer does, I'm publishing my projects for you. These projects
            are great opportunities to consolidate my skills and learn new
            techniques. Keep scrolling and check out other projects
          </Typography>

          <Typography
            fontSize={"16px"}
            width={"60%"}
            mt={3}
            textAlign={"center"}
            variant="body2"
          >
            This page contains projects I've built using the knowledge I've
            learned with technologies like React JS and JavaScript. If you want
            to check out my simpler, older or more projects, you can check out
            my GitHub profile.
          </Typography>
        </Stack>
        <Stack my={5}>
          <Grid container display={"flex"} direction={"row"} xs={12}>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Drink Order App</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    width: "300px",
                    marginBottom: "10px",
                    height: "200px",
                    borderRadius: "16px",
                  }}
                  src={drinks}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_react-frontend-webdevelopment-activity-7251924516442939392-hCcE?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/drinksOrderApp"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Rock Paper Scissors Game</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    width: "300px",
                    marginBottom: "10px",
                    height: "200px",
                    borderRadius: "16px",
                  }}
                  src={rps}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_react-materialui-frontend-activity-7252936451133239297-Hg5A?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/rockPaperScissorsGame"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Pomodoro</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    marginBottom: "10px",
                    width: "300px",

                    height: "200px",
                    borderRadius: "16px",
                    border: "1px solid orange",
                  }}
                  src={pomodoro}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_pomodoro-frontend-webdevelopment-activity-7253291311464648704-kezn?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/pomodoroApp"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>

            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Size Finder </Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    width: "300px",
                    marginBottom: "10px",
                    height: "200px",
                    borderRadius: "16px",
                  }}
                  src={findSize}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_react-frontend-webdevelopment-activity-7255099455287271424--iYy?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/sizeFindingApp"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Login & SignUp Interface</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    width: "300px",
                    marginBottom: "10px",
                    height: "200px",
                    borderRadius: "16px",
                  }}
                  src={login}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_loginsignup-frontend-webdevelopment-activity-7256556547424612352-kyLy?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/Login-SignUp"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Search New Meals</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    marginBottom: "10px",
                    width: "300px",

                    height: "200px",
                    borderRadius: "16px",
                    border: "1px solid red",
                  }}
                  src={meals}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_webdevelopment-javascript-html-activity-7268943087677173761-KrB4?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/newMeals"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
            <Grid
              mb={5}
              textAlign={"center"}
              direction={"column"}
              xs={12}
              lg={4}
            >
              <WorkIcon color="primary" />
              <Typography variant="h5">Kanban Technique</Typography>
              <Typography mx={"auto"} my={1} width={"80%"}>
                <img
                  style={{
                    marginBottom: "10px",
                    width: "300px",
                    height: "200px",
                    borderRadius: "16px",
                  }}
                  src={kanban}
                  alt=""
                />
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://www.linkedin.com/posts/enesatacan_web-webdevelopment-modern-activity-7270670800578301952-g-8v?utm_source=share&utm_medium=member_desktop"
                >
                  Browe LinkedIn
                </a>{" "}
                <a
                  target="_blank"
                  className="outlinedButton"
                  href="https://github.com/enesatacan/kanban"
                >
                  Browe GitHub
                </a>
              </div>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </>
  );
}

export default Works;
