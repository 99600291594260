import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import aboutMe from "./Images/EnesAtacanAboutMe.JPEG";
import html from "./Images/html.png";
import css from "./Images/css3.png";
import js from "./Images/js.png";
import ts from "./Images/ts.png";
import bootstrap from "./Images/bootstrap.png";
import tailwind from "./Images/tailwind.png";
import mui from "./Images/mui.png";
import reactIcon from "./Images/react.png";
import "./Style/Buttons.css";
import "./Style/Delay.css";
import Contact from "./ContactForm";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import Helmet from "react-helmet";

function AboutMe() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | About Me";
  const pageDescription =
    "Learn more about Enes ATACAN and check out his mini autobiography";
  const keywords = "Enes Atacan, Hakkımda, About Me,Instagram";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar
  return (
    <Box className={`delay ${showText ? "show" : ""}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="noindex, follow" />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Grid container height={"100vh"}>
        <Grid mt={15} mb={10} style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography variant="h2">About Me</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
                <Typography variant="body2">Enes ATACAN</Typography>
              </div>
            </div>
            <Typography variant="body2">
              For more information please contact...
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={6} bgcolor={"#f3f4f1"}>
          <img
            src={aboutMe}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              zIndex: 0,
              objectFit: "cover",
              opacity: 0.9,
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
            }}
            alt="Enes Atacan About Me"
          />
        </Grid>
      </Grid>

      <Grid height={"50%"} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Stack py={5} width={"60%"}>
            <Typography color={"#5f8680"} variant="h4">
              Hello,
            </Typography>
            <Typography mt={2} variant="body2">
              Hello there! I am Enes Atacan. As a Frontend Developer, I develop
              user-friendly, mobile-friendly and aesthetic websites with modern
              web technologies such as HTML, CSS, JavaScript, TypeScript, React.
            </Typography>
            <Typography my={2} variant="body2">
              Thanks to my curiosity about technology and my researcher
              personality, I improve myself in every project and work to
              maximize the user experience. I create solutions that increase
              efficiency and performance with tools such as Vite, Tailwind CSS,
              Bootstrap and Material UI.
            </Typography>
            <Typography mb={2} variant="body2">
              Throughout my 2 years of experience, it has been a priority for me
              to optimize UI/UX designs to facilitate user interaction, ensure
              cross-browser compatibility and produce projects that comply with
              modern web standards.
            </Typography>
            <Typography mb={2} variant="body2">
              I also actively follow the process by adhering to the agile
              methodology in project management and develop fast solutions by
              taking user feedback into account.
            </Typography>
            <Typography mb={2} variant="body2">
              My goal is to continue to be a competent and solution-oriented
              Developer in my field by following the developments in web
              technologies.
            </Typography>
            <Typography mb={2} variant="body2">
              If you would like to get to know me in more detail or learn more
              about my projects, feel free to contact me! 😊
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <Button>
                <img src={html} style={{ width: "30px" }} />
              </Button>
              <Button>
                <img src={css} style={{ width: "30px" }} />
              </Button>
              <Button>
                <img src={js} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={ts} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={bootstrap} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={tailwind} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={mui} style={{ width: "50px" }} />
              </Button>
              <Button>
                <img src={reactIcon} style={{ width: "50px" }} />
              </Button>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Grid height={"100%"} pt={5} container bgcolor={"#e7e9e2"}>
        <Grid
          item
          xs={12}
          lg={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography style={{ color: "#557772" }} variant="h4">
            Contact Me
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />
          <Typography variant="body2" sx={{ letterSpacing: "0.1em" }}>
            I look forward to getting in touch with you.
          </Typography>
          <Typography
            width={"80%"}
            textAlign={"center"}
            variant="body2"
            mt={1}
            sx={{ letterSpacing: "0.1em" }}
          >
            You can send me a message via the form below or you can contact me
            by following me on my social media accounts.
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />

          <Stack spacing={5} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <Contact />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AboutMe;
