import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import IDU from "./Images/DemocracyUniversity.png";
import IU from "./Images/IstanbulUniversity.png";
import nhuman from "./Images/nhuman.webp";
import "./Style/Buttons.css";
import "./Style/Delay.css";
import Contact from "./ContactForm";
import CV from "./CV/Enes_ATACAN_CV.pdf";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import Helmet from "react-helmet";

function Portfolio() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;
  const [showText, setShowText] = useState(false);
  const pageTitle = "Enes ATACAN | Portfolio";
  const pageDescription =
    "Check out Enes ATACAN's projects, achievements, past experiences, certificates, CV and much more  ";
  const keywords =
    "Enes Atacan, Portföy, Portfolio, Projeler, React, Frontend Developer, CV,Projects, Jobs";

  useEffect(() => {
    // ComponentDidMount benzeri davranış için useEffect kullanıyoruz
    const timer = setTimeout(() => {
      setShowText(true); // .1 saniye sonra metni göster
    }, 100);

    // Temizleme fonksiyonu: bileşen unmount olduğunda zamanlayıcıyı temizle
    return () => clearTimeout(timer);
  }, []); // [] içindeki boş dependency array sadece bir kere çalıştırılmasını sağlar
  return (
    <Box className={`delay ${showText ? "show" : ""}`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="noindex, follow" />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <Grid container height={"100vh"}>
        <Grid style={{ bgcolor: "#f3f4f1" }} xs={12} lg={6}>
          <div
            style={{
              width: "60%",
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <Typography variant="h2">Portfolio</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    marginRight: 20,
                    width: "20%",
                    backgroundColor: "black",
                  }}
                />
                <Typography variant="body2">Enes ATACAN</Typography>
              </div>
            </div>
            <Typography mb={1} variant="body2">
              Hello, you can review my CV with the 'Download My CV' button or
              keep scrolling to review my portfolio...
            </Typography>
            <Typography mt={1} variant="body2">
              You can browse the large and small projects I have done through
              the 'Other Projects' button below.
            </Typography>
            <Stack mt={2}>
              <Link to={"/works"}>
                <button className="outlinedButton">Other Projects</button>
              </Link>
            </Stack>
            <Stack spacing={2} direction={"row"} mt={3}>
              <IconButton target="blank" href={GitHubLink}>
                <GitHubIcon color="black" />
              </IconButton>

              <IconButton target="blank" href={LinkedinLink}>
                <LinkedInIcon color="black" />
              </IconButton>
            </Stack>
          </div>
        </Grid>
        <Grid
          item
          boxShadow={"5px 0px 5px rgba(0, 0, 0, 0.5)"}
          xs={12}
          lg={6}
          bgcolor={"#daddd4"}
        >
          <Stack
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
              position: "relative",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Link to={CV} target="_blank" download="Enes_ATACAN_CV.pdf">
              <button className="containedButton">Download My CV</button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
      <Grid py={5} height={"50%"} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Stack width={"60%"}>
            <Typography color={"#557772"} my={2} variant="h4">
              Summary
            </Typography>
            <Typography variant="body2">
              Hello, I'm Enes ATACAN, I completed my undergraduate education in
              MIS and I have been working as a freelance Frontend Developer in
              the industry for about two years. I specialize in technologies
              such as HTML, CSS, Bootstrap, Tailwind, JavaScript, TypeScript,
              React, Redux, Material UI and NPM, providing mobile-friendly
              designs and impressive user experiences. Thanks to my curious and
              inquisitive nature, I always focus on producing the best solutions
              in my projects by closely following the innovations in technology.
              My goal is to make people's lives easier with creative web
              solutions and take their business one step further in digital.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid py={2} container bgcolor={"#e7e9e2"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={2}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Stack width={"60%"}>
            <Typography color={"#557772"} variant="h4">
              {" "}
              Experience Background
            </Typography>
            <Stack my={2}>
              <Typography color={"#5f8680"} fontWeight={"normal"} variant="h6">
                My Freelance Work
              </Typography>
              <Typography variant="body2">
                My Free Works During my education life, I tried to direct my
                individual studies and business life, I did the work I
                encountered at the beginning through wordpress, but since I
                wanted to improve myself in professional software life, I took a
                break from my work with WordPress and did not publish my
                projects. Later, I developed some projects using HTML, CSS,
                Bootstrap, Tailwind, JavaScript, React and shared them as my
                personal experiences, especially on my LinkedIn and GitHub
                pages. You can review these experiences under the Other Projects
                section above. Finally, by improving myself with ReactJS and
                MUI, today I have 1 published project under 'Enes ATACAN'. This
                project is a blog website that can bridge all the stores of a
                business that has stores on global e-commerce addresses and
                wants to promote itself:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "bolder",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  href="https://cetmart.com/"
                >
                  cetmart.com
                </a>
                .
              </Typography>
            </Stack>
            <Stack my={2}>
              <Typography color={"#5f8680"} fontWeight={"normal"} variant="h6">
                WEB DEVELOPMENT ASSISTANT
              </Typography>
              <Stack
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                  href="https://www.nhumandanismanlik.com/"
                >
                  <img
                    width={"128px"}
                    src={nhuman}
                    alt="nhumandanismanlik.com"
                  />
                </a>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bolder",
                    marginBottom: "10px",
                    color: "black",
                    opacity: 0.7,
                  }}
                >
                  NHUMAN - Izmir - (October 2023 - June 2024)
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                sx={{ color: "black", opacity: 0.85, marginBottom: 2 }}
              >
                I worked part-time at NHUMAN for 8 months to develop two
                different websites.
              </Typography>
              <Typography mb={1} variant="body2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                  href="https://www.nhumandanismanlik.com/"
                >
                  nhumandansanismanlik.com
                </a>
                : I redesigned and developed the blog sections of the website
                using HTML/CSS through a dashboard.
              </Typography>
              <Typography mb={2} variant="body2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                  href="https://www.worknhuman.com/"
                >
                  worknhuman.com
                </a>
                : I developed various UI/UX designs using WordPress, integrated
                new plugins and worked to improve the user experience of the
                website.
              </Typography>
              <Typography mb={2} variant="body2">
                <b>Web Traffic and SEO Optimization:</b> I contributed to
                performance management with strategies, content edits and
                keyword optimization to increase the visibility of websites.
              </Typography>
              <Typography mb={2} variant="body2">
                <b>Project Management with Agile Principles:</b> I organized
                daily meetings, planned project processes and ensured team
                coordination
              </Typography>
              <Typography mb={2} variant="body2">
                <b>Customer Feedback and Problem Solving:</b> I was able to
                generate quick solutions to customer needs, analyze errors and
                increase satisfaction
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid py={2} height={"50%"} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={6}
        >
          <Stack>
            <Typography color={"#557772"} variant="h4">
              Education
            </Typography>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              mt={2}
            >
              <img
                src={IDU}
                alt="Enes Atacan Undergraduate"
                width={"32px"}
                height={"32px"}
              />
              <Typography my={2} variant="body2">
                İzmir Democracy University
              </Typography>
            </Stack>

            <Typography variant="body2" my={1}>
              Management Informaton Systems
            </Typography>
            <Typography variant="body2" mb={2}>
              10.2020 - 07.2024
            </Typography>
            <Divider />
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={2}
              mt={2}
            >
              <img
                src={IU}
                alt="Enes Atacan Online Associate Degree"
                width={"32px"}
                height={"32px"}
              />
              <Typography my={2} variant="body2">
                İstanbul University
              </Typography>
            </Stack>

            <Typography variant="body2" my={1}>
              E-Commerce and Marketing
            </Typography>
            <Typography variant="body2" mb={2}>
              09.2022 - Continues
            </Typography>
          </Stack>
        </Grid>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={6}
        >
          <Stack>
            <Typography color={"#557772"} variant="h4">
              {" "}
              Certificates & Achievements
            </Typography>
            <Stack mt={2} spacing={2}>
              <Typography variant="body2">
                React JS : Hands-on React JS -Redux Training (2024) / Can Boz
              </Typography>
              <Typography variant="body2">
                React Course from Scratch - React Training from Zero to Expert
                (2024) / Emin Başbayan
              </Typography>
              <Typography variant="body2">
                IT and System Support Specialist / Google IT Course (2024) /
                Alparslan Ege Cihan
              </Typography>
              <Typography variant="body2">
                API (Interface) Testing and Test Automation with Postman (2024)
                / Ozan İlhan
              </Typography>
              <Typography variant="body2">
                Introduction to Software Testing (2024) / Ahmet Bulutluöz
              </Typography>
              <Typography variant="body2">
                Advanced Modern Javascript ES7+ from Scratch (2023) / Sadık
                Turan
              </Typography>
              <Typography variant="body2">
                Web Development with HTML5 (2023) / Fahrettin Erdinç
              </Typography>
              <Typography variant="body2">
                Version Controls: Git and GitHub (2023) / Atıl Samancıoğlu
              </Typography>{" "}
              <Typography variant="body2">
                Project Management Basics (2023) / Emre Alıç
              </Typography>
              <Typography variant="body2">
                Website Availability (2022) / Fatih Tekmen
              </Typography>
              <Typography variant="body2"></Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid py={2} container bgcolor={"#f3f4f1"}>
        <Grid
          textAlign={"center"}
          display={"flex"}
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={2}
          style={{ bgcolor: "#f3f4f1" }}
          xs={12}
          lg={12}
        >
          <Stack>
            <Typography color={"#557772"} variant="h4">
              {" "}
              Competences
            </Typography>
            <Typography mb={2} variant="body2" component="div">
              <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    HTML / CSS / Bootstrap / Taiwind
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    JavaScript / TypeScript
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    PHP
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    Vite + ReactJS
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    Material UI
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    NPM
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    UI & UX Design
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    WordPress
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    Presentation Skills
                  </Typography>
                </Stack>
                <Stack my={1}>
                  <Typography
                    color={"#5f8680"}
                    variant="body2"
                    fontWeight="bold"
                  >
                    Communication
                  </Typography>
                </Stack>
              </ul>
              <Stack
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
                direction={"row"}
                mt={3}
              >
                <IconButton target="blank" href={GitHubLink}>
                  <GitHubIcon color="black" />
                </IconButton>

                <IconButton target="blank" href={LinkedinLink}>
                  <LinkedInIcon color="black" />
                </IconButton>
              </Stack>
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid height={"100%"} pt={5} container bgcolor={"#e7e9e2"}>
        <Grid
          item
          xs={12}
          lg={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography style={{ color: "#557772" }} variant="h4">
            Contact Me
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />
          <Typography variant="body2" sx={{ letterSpacing: "0.1em" }}>
            I look forward to getting in touch with you.
          </Typography>
          <Typography
            width={"80%"}
            textAlign={"center"}
            variant="body2"
            mt={1}
            sx={{ letterSpacing: "0.1em" }}
          >
            You can send me a message via the form below or you can contact me
            by following me on my social media accounts.
          </Typography>
          <Divider sx={{ width: "80%", marginY: 1 }} />

          <Stack spacing={5} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <Contact />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Portfolio;
