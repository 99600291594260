import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom"; // react-router-dom kullanıyorsanız

function Footer() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  return (
    <Box textAlign={"center"} p={2} color={"white"} bgcolor={"#5d644f"}>
      <Box mb={1} display="flex" justifyContent="center" alignItems="center">
        <Typography>
          <Link
            component={RouterLink}
            to={"/"}
            className="navbarBtn"
            color="inherit"
            sx={{ textDecoration: "none" }}
          >
            Home
          </Link>
        </Typography>
        <Typography mx={2}>|</Typography> {/* Pipe karakteri ekledik */}
        <Typography>
          <Link
            component={RouterLink}
            to={"/about"}
            className="navbarBtn"
            color="inherit"
            sx={{ textDecoration: "none" }}
          >
            About Me
          </Link>
        </Typography>
        <Typography mx={2}>|</Typography> {/* Pipe karakteri ekledik */}
        <Typography>
          <Link
            component={RouterLink}
            to={"/portfolio"}
            className="navbarBtn"
            color="inherit"
            sx={{ textDecoration: "none" }}
          >
            Portfolio
          </Link>
        </Typography>
        <Typography mx={2}>|</Typography> {/* Pipe karakteri ekledik */}
        <Typography>
          <Link
            component={RouterLink}
            to={"/contact"}
            className="navbarBtn"
            color="inherit"
            sx={{ textDecoration: "none" }}
          >
            Contact
          </Link>
        </Typography>
      </Box>
      <Typography variant="body2">
        &copy; {year} Enes Atacan. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
