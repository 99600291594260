import React from "react";
import Err404 from "./Images/404.svg";
function ErrorPage() {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <img
          style={{
            width: 400,
            display: "flex",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          src={Err404}
          alt="404"
        />
      </div>
    </>
  );
}

export default ErrorPage;
