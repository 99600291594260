import {
  AppBar,
  Box,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItemText,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./Style/Navbar.css";
import burgerLogo from "./Images/burgerLogo.png";
import { Link, useLocation } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Links from "./Links";
import { useState, useEffect } from "react";

function Navbar() {
  const { InstagramLink, GitHubLink, LinkedinLink } = Links;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const [hideNavbar, setHideNavbar] = useState(false); // Navbar'ın gizli olup olmadığını izleyen state
  const [prevScrollPos, setPrevScrollPos] = useState(0); // Önceki kaydırma pozisyonu
  const [visible, setVisible] = useState(true); // Navbar'ın görünürlüğü
  const location = useLocation();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 80); // Yukarı doğru kaydırıldığında veya sayfanın üstündeyken Navbar'ı göster
    setHideNavbar(prevScrollPos < currentScrollPos && currentScrollPos > 80); // Aşağı doğru kaydırıldığında ve belirli bir eşiğin altındaysa Navbar'ı gizle

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const getLinkStyle = (path) => ({
    textDecoration: "none",
    color: location.pathname === path ? "#3f5a56" : "black",
    fontWeight: location.pathname === path ? "bold" : "",
    transition: location.pathname === path ? ".1s ease-in-out" : "",
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Box width={"100%"} style={{ position: "absolute" }}>
      <AppBar
        sx={{
          my: "auto",
          backgroundColor: "white",
          paddingY: 1,
          display: { xs: "none", md: "block" }, // Masaüstü için görünürlük ayarı
          opacity: visible ? 0.7 : 0, // Scroll yönlü olarak opacity ayarı
          transition: "opacity 0.3s ease-in-out",
        }}
        className="appbar"
        position="fixed"
      >
        <Toolbar>
          <Link
            to={"/"}
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "32px",
              fontWeight: "light",
            }}
            className="navbarBtn"
          >
            Enes ATACAN
          </Link>

          <Stack
            direction="row"
            spacing={6}
            sx={{ marginLeft: "auto", alignItems: "center" }}
          >
            <Link to={"/"} style={getLinkStyle("/")} className="navbarBtn">
              Home
            </Link>
            <Link
              to={"/about"}
              style={getLinkStyle("/about")}
              className="navbarBtn"
            >
              About Me
            </Link>
            <Link
              to={"/portfolio"}
              style={getLinkStyle("/portfolio")}
              className="navbarBtn"
            >
              Portfolio
            </Link>
            <Link
              to={"/contact"}
              style={getLinkStyle("/contact")}
              className="navbarBtn"
            >
              Contact
            </Link>
            <Typography color={"black"}>|</Typography>
            <Stack direction={"row"}>
              <IconButton target="blank" href={GitHubLink}>
                <GitHubIcon color="black" />
              </IconButton>

              <IconButton target="blank" href={LinkedinLink}>
                <LinkedInIcon color="black" />
              </IconButton>

              <IconButton target="blank" href={InstagramLink}>
                <InstagramIcon color="black" />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <AppBar
          sx={{
            backgroundColor: "white",
            paddingY: 1,
            opacity: ".75",
            display: "flex",
            color: "black",
            justifyContent: "space-between",
          }}
          position="static"
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Link
              to={"/"}
              style={{ textDecoration: "none", color: "black" }}
              className="navbarBtn"
            >
              <Typography color={"inherit"}>Enes ATACAN</Typography>
            </Link>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              fontWeight={"bold"}
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="right"
          open={isMobileMenuOpen}
          onClose={toggleMobileMenu}
          PaperProps={{
            sx: {
              backgroundColor: "#daddd4",
            },
          }}
        >
          <Box
            sx={{
              width: 250,
              padding: "20px",
            }}
          >
            <List>
              <>
                <img
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  src={burgerLogo}
                />
              </>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/"}
              >
                <ListItemText className="burgerBtn" primary="Home" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/about"}
              >
                <ListItemText className="burgerBtn" primary="About" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/portfolio"}
              >
                <ListItemText className="burgerBtn" primary="Portfolio" />
              </Link>

              <Link
                style={{ textDecoration: "none" }}
                onClick={toggleMobileMenu}
                to={"/contact"}
              >
                <ListItemText className="burgerBtn" primary="Contact" />
              </Link>
            </List>
          </Box>
          <Stack justifyContent={"space-around"} direction={"row"} mt={3}>
            <IconButton target="blank" href={GitHubLink}>
              <GitHubIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={LinkedinLink}>
              <LinkedInIcon color="black" />
            </IconButton>

            <IconButton target="blank" href={InstagramLink}>
              <InstagramIcon color="black" />
            </IconButton>
          </Stack>
          <Typography
            variant="body2"
            backgroundColor="#5d644f"
            mt={"auto"}
            color={"white"}
            textAlign={"center"}
            padding={1}
          >
            &copy; {year} Enes Atacan. All rights reserved.
          </Typography>
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
