import React, { createElement } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import AboutMe from "./Components/AboutMe";
import Works from "./Components/Works";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Portfolio from "./Components/Portfolio";
import ErrorPage from "./Components/ErrorPage";
import { createTheme, ThemeProvider } from "@mui/material";
import ScrollToTopOnPageChange from "./Components/ScrollToTopOnPageChange";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Segoe UI",
      "Helvatica",
      "Ubuntu",
      "Trebuchet MS",
      "sans-serif", // Son seçenek olarak genel sans-serif fontu
    ].join(","),
  },
  palette: {
    primary: {
      main: "#5f8680",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <ScrollToTopOnPageChange />

          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<AboutMe />}></Route>
            <Route path="/portfolio" element={<Portfolio />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/works" element={<Works />}></Route>
            <Route path="*" element={<ErrorPage />}></Route>
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
